import React from "react"

import {
  BannerPage,
  ButtonPrimary,
  InternalLink,
  Layout,
  SEO
} from "../components"
import { InternalLinkTypes } from "../components"
import { Result } from "antd"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" description={"Page not found"} />
    <BannerPage title={"Page not found"} />
    <div
      style={{
        textAlign: "center"
        // backgroundColor: Colors.LightGray
      }}
    >
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <ButtonPrimary>
            <InternalLink url={InternalLinkTypes.Home}>Back Home</InternalLink>
          </ButtonPrimary>
        }
      />
    </div>
  </Layout>
)

export default NotFoundPage
